.box {
    color: green;
}

.contract {
/*     position: relative; */
     display: -ms-flexbox;
     display: inline-flex;
     -ms-flex-direction: column;
     flex-direction: column;
     min-width: 0;
     word-wrap: break-word;
	  padding-top: 15px;
	  padding-bottom: 15px;
 }

 .step {
     word-wrap: break-word;
     /*
	  padding-top: 0.5rem;
	  padding-bottom: 0.5rem;
*/
 }

 .stepcol {
     /*
	   padding-left: 15px;
	   padding-right: 15px;
    */
 }

input, select {
	 height: 30px;
	 /* box-sizing: border-box;*/
	 border: 1.5px solid #004262;
	 margin: 2px;
}

button {
	 border: 1.5px solid #004262;
	 margin: 4px;
}

input[type=number].days {
	 width:42px;
}

input[type=number].price {
	 width:80px;
}

input[type=number].numpersons {
	 width:42px;
}

input[type=number].margin_input {
	 width:70px;
}

input[type=date] {
    width: 142px;
}

div.price {
	 text-align: right; 
}

h5 {
	 margin: 0.5rem;
}

.group-heading {
	 text-align: center;
	 width: 100%;
}

.app_title {
    font-size: xx-large;
    display: inline-block;
    position:relative;
    top: 4px;
}

.headline {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: x-large;
    color: #004262;
}

.w-40 {
    width: 42px;;
    height: 42px;
}

.manual_head {
	 margin-left: 0.5rem;
	 margin-right: 0.5rem;
}

div.contract_name {
	 margin-left: 0.5rem;
	 margin-right: 0.5rem;
	 line-height: 42px;
}

div.contract_name span {
	 display: inline-block;
	 vertical-align: middle;
	 line-height: normal;
}

.avatar {
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15);
	 
    color: #fff;
    border: none;
    background: #004262 linear-gradient(45deg, #70360a, #ea771f);
	 /* background: hwb(50deg,50%,50%); */
}

.avatar img {
    border-radius: inherit;
    width: 100%
}

.text-color {
    /*color: #5e676f*/
}

.text-sm {
    font-size: .825rem
}

.h-1x {
    height: 1.25rem;
    /* overflow: hidden; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.no-wrap {
    white-space: nowrap
}

.list-row .contract {
     -ms-flex-direction: row;
     flex-direction: row;
 }

.step {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #5e676f;
}

.step:last-child {
    border-bottom: 0;
}

.dropdown {
    position: relative
}

#left_column {
    color: #000;
    background-color: #cdd9e0;
    float: left;
    width: 30%;
    padding: 15px;
    height: 100%;
}

#right_column {
    background-color: #faddc7;
    float: right;
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-top: 15px;
	  /*padding: 0;*/
    height: 100%;
}

#contracts_list {
    height: 75%;
    overflow-y: auto;
}

#right_head_row {
    /* height: 10%; */
}

.grisling_padded {
    padding-left: 15px;
    padding-right: 15px;
}

#right_contents_row {
    background-color: #faddc7;
    height:62%;
}

#steps_list {
    overflow-y: scroll;
    height:90%;
}

.totals {
    text-align: right;
    background-color: #e0e0e0;
    width: 100%;
    margin-left:0;
}

.variant-button {
    padding: 1px;
    margin: 2px;
    line-height: var(--bs-body-line-height);
    border: 1px;
}

.variant-item {
    padding: 4px;
    display: block;
}

#top_bar {
	  padding: 15px;
	 width: 100%;
	 background-color: #ea771f;
}

#top_bar,.button_bar {
	 display:inline-block;
}

#top_bar,.login_bar {
	 float: right;
}

body,html {
	 height: 100%;
   overflow: hidden;
}

.container-fluid {
	  height: 100%;
}
